



import { createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../util/Api';

const initialState = {
  property_types: null,
  property_subtypes: null,
  deal_types: null,
  bhk: null,
  furnitures: null,
  possessions: null,
  areas: null,
  cities: null,
  societies: null,
  contacts: null,
  contactgroups: null,
  subusers: null,
  loading: false,
  error: null,
};

export const namesSlice = createSlice({
  name: 'names',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setData: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
    namesLogout: () => initialState,
  },
});

export const { setLoading, setError, setData, namesLogout } = namesSlice.actions;

// Utility function to fetch all paginated results
const fetchAllResults = async (url, token, accumulatedResults = []) => {
  const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;
  const response = await fetch(fullUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `JWT ${token}` : undefined,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
  }

  const data = await response.json();
  accumulatedResults = accumulatedResults.concat(data.results);

  return data.next ? fetchAllResults(data.next, token, accumulatedResults) : accumulatedResults;
};

// Generic thunk to fetch data and dispatch it
const fetchDataThunk = (endpoint, key) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const token = getState().auth.accessToken;

  try {
    const data = await fetchAllResults(endpoint, token);
    dispatch(setData({ key, data }));
  } catch (error) {
    dispatch(setError(error.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

// Specific thunks for each data type
export const fetchAllData = () => async (dispatch) => {
  const endpoints = [
    { url: '/properties/propertytype', key: 'property_types' },
    { url: '/properties/propertysubtype', key: 'property_subtypes' },
    { url: '/properties/deal_type', key: 'deal_types' },
    { url: '/properties/bhk', key: 'bhk' },
    { url: '/properties/furniture', key: 'furnitures' },
    { url: '/properties/possession', key: 'possessions' },
  ];

  dispatch(setLoading(true));
  try {
    await Promise.all(
      endpoints.map(({ url, key }) => dispatch(fetchDataThunk(url, key)))
    );
  } catch (error) {
    dispatch(setError(error.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchCity = () => fetchDataThunk('/properties/city', 'cities');
export const fetchAreas = () => fetchDataThunk('/properties/area', 'areas');
export const fetchSocieties = () => fetchDataThunk('/properties/society', 'societies');
export const fetchContacts = () => fetchDataThunk('/properties/contacts', 'contacts');
export const fetchContactGroup = () => fetchDataThunk('/properties/contactgroup', 'contactgroups');
export const fetchSubUsers = () => fetchDataThunk('/properties/subuserlist', 'subusers');

export default namesSlice.reducer;
