// propertySlice.js

import {  createSlice } from '@reduxjs/toolkit';
// import { BASE_URL } from '../../util/Api';

const initialState = {
  properties: [],
  inquiries:null
}

export const propertySlice = createSlice({
  name: 'property', 
  initialState,
  reducers: {
    // increment: (state) => {
    //   state.asdf += 1
    // },
    // decrement: (state) => {
    //   state.asdf -= 1
    // },
    getProperty: (state, action) => {
      state.properties = action.payload;
    },
    getInquiry: (state, action) => {
      state.inquiries = action.payload;
    },
   
  },
 
})

export const {getProperty,getInquiry } = propertySlice.actions

export default propertySlice.reducer;
